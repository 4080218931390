
export const AboutUs = () => {

    return (
        <section className="section-padding">
            <div className="container">
                <div className="row">

                    <div className="col-lg-10 col-12 text-center mx-auto">
                        <h2 className="mb-5">За Нас</h2>
                    </div>

                    <div className="custom-text-box">

                        <p>Сдружение „Данни за добро“, Фондация „Общество.бг“ и Антикорупционният фонд (АКФ), заедно
                            с десетки журналисти, медии, обществени лица и доброволци обявиха днес създаването на
                            „Обединение за честни избори“ (ОЧИ) – платформа, която има за цел да води борба срещу
                            изборните измами, купуването на гласове и контролирания вот.</p>

                        <p>ОЧИ ще използва данни, за да насочва вниманието на медии и доброволци към местата в
                            страната с изборни аномалии, които са индикатор за купен вот или друг вид измами.</p>

                        <p>Обединението се създава, след като през декември 2022 г. бяха приети промени в Изборния кодекс, които връщат един от големите инструменти за потенциални изборни измами – невалидните бюлетини. През годините имаше има множество доказателства, изказвания и сигнали, че невалидните бюлетини се използват, за да заличават легитимни гласове на български граждани.</p>

                        <p>Сред организациите, които застават зад ОЧИ, влизат още “Фондация за Общностно Развитие и Сътрудничество”, “Дневник”, „Медиапул“, „Стража“, „БлуЛинк“, “Благоевград нюз”, “За истината”, “Зов нюз” и други. ОЧИ е отворена към работа с още организации и медии, които припознават риска от изборни измами като основен проблем за България и политическото представителство в страната.</p>

                        <p>Приоритет на ОЧИ през 2023 г. ще бъдат местните избори през есента. При евентуални предсрочни парламентарни избори, организацията също ще помага за опазването на честността на вота.</p>

                        <p>Освен, че ще анализира данни и насочва вниманието на медии и доброволци, ОЧИ ще организира и публични обучения, на които журналисти и граждански активисти ще споделят знания за различни видове манипулации на вота, опита си при отразяване на избори и др.</p>

                        <p>Работата на ОЧИ ще стъпи на анализите на секции в риск от контролиран и купен вот, публикувани през 2021 и 2022 от АКФ и „Данни за добро“, за да определи секциите, които имат нужда от най-много наблюдатели.</p>

                        <p>„Информацията, която събираме в ОЧИ, ще ни позволи да подобрим методологията ни за оценка на секции в риск, с фокус към местни избори и превенция на изборни измами. Искаме да постигнем по-добро визуално представяне и събиране на аудио-визуални материали преди и в самия изборен ден, включително и чрез отразяване в реално време от мястото на събитието“, казва Никола Тулечки, създател на „Данни за добро“.</p>

                        <p>Една от целите на ОЧИ е да поддържа висок интерес по темата за честността на вота в България извън дните непосредствено преди изборите и при приемането на поправки в Избирателния кодекс.</p>

                        <p>„Амбицията ни е Обединението за честни избори да бъде траен обществен фактор, който да допринесе за честно провеждане на изборите в България“, казва Бойко Станкушев, директор на АКФ.</p>

                    </div>

                </div>
            </div>
        </section>
    );
};