export const Reports = () => {

    return (
        <section className="section-padding" id="reports">
            <div className="container">
                <div className="row">

                    <div className="col-lg-10 col-12 text-center mx-auto">
                        <h2 className='pb-0'>Предварителен доклад</h2>
                        <h3 className='pt-0 pb-1'>от наблюдението и анализите на предсрочните парламентарни избори в България на 2 април 2023 г.</h3>
                    </div>

                    <div className='col-12 p-5'>
                        <p>Партньорите по този проект имаха за цел оценка на условията за свободно и честно изразяване на политическа воля по време на изборния процес, още от подготовката на изборите. Фокусът беше върху конкретни райони на страната, където различни специфични условия през годините допринесоха за, и събраха достатъчно доказателства през годините за подкрепа на твърденията за купуване на гласове и корпоративно гласуване.</p>

                        <h4>Партньорство за реализация на проекта</h4>

                        <p>Основните партньори на Обединението за честни избори, ОЧИ , бяха:</p>

                        <ul>
                            <li>Антикорупцият фонд</li>
                            <li>Данни за добро</li>
                            <li>Общество БГ ( Society BG)</li>
                            <li>Европейската асоциация за защита правата на човека - България, ADHR-BG</li>
                        </ul>

                        <p>Към ключовите партньори много скоро се присъединиха представители на много медии и отделни журналисти, от официални и неформални граждански инициативи, обединени от значимата за цялото общество цел за координирано безпристрастно наблюдение на изборите и стандартизирано ежедневно отчитане в цялата страна.</p>

                        <h4>Етапите на процеса включваха:</h4>
                        <ul>
                            <li>анализ на съществуващи данни от предишни избори за идентифициране на потенциални проблемни области</li>
                            <li>проверка и кръстосани проверки на целевите зони</li>
                            <li>семинари и обучения с журналисти относно прилаганата методология за събиране и докладване на данни, както и разчитането и тълкуването на докладваните въпроси</li>
                            <li>мобилизиране на доброволци за предизборно наблюдение и обучение</li>
                            <li>разполагане на екипи от наблюдатели за пълно покриване на електронния ден в предварително идентифицираните целеви зони</li>
                            <li>Наблюдение на електронния ден</li>
                            <li>анализ и представяне пред обществеността на откритията</li>
                        </ul>

                        <p>В резултат на отличната подготовка и безупречното сътрудничество между медиите, експертната общност и обучените граждански наблюдатели, целенасоченото наблюдение достигна обхват доста повече от всяка друга гражданска инициатива досега и благодарение на най-високото ниво на експертиза, демонстрирано на всички нива и етапи, спечели получи заслужено обществено признание и се превърна в отправна точка за всички. През дните и първите седмици след електронния ден всяка медия, с национално и регионално покритие, излъчи данни и анализ на ситуацията, така както е видяна през очите на проекта.</p>

                        <p>Подготовката започна много преди изборната дата с начална среща между ключовите заинтересовани страни, които дефинираха индивидуални и общи задачи, набелязаха предизвикателствата и планираха действията. Това близко до перфектното планиране, заедно с незаменимия доброволен принос от отделни експерти и мрежи, гарантираха устойчив успех по време на упражнението.</p>

                        <h6>1. Анализ на съществуващи данни от предишни избори за идентифициране на потенциални проблемни области</h6>
                        <p>Поради липса на ресурси и за разлика от стандартната местна и международна методология за наблюдение на избори, това наблюдение предприе нетрадиционен подход за наблюдение в електронния ден, особено когато става въпрос за избор на целевите зони и избирателни секции (БИ). За разлика от стандартните мисии за наблюдение на избори, провеждани от ЕС, ОССЕ/СДИПЧ и други национални и международни наблюдатели, които изграждат безпристрастна обща картина на изборния процес, целта за 2 април 2023 г. беше да се ограничи обхватът и да се обхванат конкретни, предварително избрани и се оказаха проблемни избирателни секции. Предварителните подбори се основаваха на техническа оценка на избирателните секции с по-голям риск, проведени с фокус върху: ( i ) прекалено висока избирателна активност, (ii) прекалено висок вот за партия или коалиция в сравнение с близките избирателни секции и (iii ) избирателни секции на места, където доклади на наблюдатели или доказателства от новините предполагат контролиран вот в миналото.</p>
                        <p>По този начин екипът е избрал избирателни секции, които са показали по време на предишни избори значителни статистически аномалии, трудно обясними с обичайното електорално поведение. Някои са в контраст не само със средното за страната, но и с близките избирателни секции, дори и в същото населено място. Въз основа на тези данни хипотезата на екипа е, че тези станции може да са (или са били) обект на изборна измама и/или контролирано гласуване.</p>

                        <h6>2. Проверка на целевите зони и избирателни секции</h6>
                        <p>Целта беше статистически да се докаже наличието на контролиран вот и да се разкрие по много нагледен за широката публика начин фундаменталната същност на незаконните практики в изборния процес в България.</p>
                        <p>Ръка за ръка , данни, електорални и политически анализатори работиха върху голяма част от предварително избраните PS и елиминираха значителен брой от тях поради произволни влияния в конкретния период.</p>
                        <p>В края на процеса на проверка всички планирани за наблюдение избирателни секции притежаваха една или повече от следните характеристики:</p>
                        <ul>
                            <li>Извън всяко разумно съмнение, контролираният вот беше налице поне за някои избори</li>
                            <li>Социалните и икономически характеристики предполагат икономически затруднения и възможни опити на дилъри да подложат избирателите на външни влияния</li>
                            <li>Поради продължаващата бедност гласоподавателите изразиха готовност да мобилизират подкрепа в замяна на парични или други стимули</li>
                            <li>Моноиндустриални зони с един или ограничен работодател/и, които влияят на гласовете дори без транзакции</li>
                            <li>Ромски, турски или други отдалечени и/или изолирани райони, където основните канали за информация не са доминиращи</li>
                        </ul>

                        <h6>3. Подписване на ангажимент за градивна и позитивна политическа кампания</h6>
                        <p>По средата на официалната кампания EYES беше домакин на публично събитие, като покани политическите партии да станат подписали доброволен ангажимент за конструктивни и позитивни политически кампании. На събитието присъстваха 17 посланици и дипломати от страни членки на ЕС, 4 от други посолства, и четирите работещи в България германски политически фондации, Ханс Зайдел, Конрад Аденауер, Фридрих Еберт и Фридрих Науман.</p>
                        <p>За съжаление на събитието присъстваха само няколко политически партии, Ние продължаваме промяната, Демократична България, Български възход и Левицата, без изрично обяснение от страна на неучаствалите.</p>

                        <h6>4.	Работилници и обучения за журналисти</h6>
                        <p>На медийни издания и независими журналисти бяха предоставени две обучения с различни ъгли, едното, организирано от ACF, а другото, от Асоциацията на европейските журналисти.</p>
                        <p>Подробно беше разяснена методологията на целенасоченото наблюдение, както и причините за фокусирането върху проблемните аспекти на процеса. По време на обучителните сесии и семинарите беше подчертано, че оценката на процеса, както и окончателният доклад, не твърдят, че е изчерпателен и способен да оцени цялостната безпристрастност на изборите. За такава обща оценка екипът се възползва от коментари и експертиза. Вместо това беше обяснено как целенасоченият подход предлага безценни прозрения и осветява някои от най-предизвикателните региони в България по отношение на изборния процес, от една страна, и дълбинните причини за недоверието, което като цяло се свързва с тези области.</p>
                        <ul>
                            <li>неравенства между обществените и частните медии в тяхното свободно програмиране към по-добре информирани политически решения (тъй като законът диктува еднакво пространство за всички партии и коалиции, въпреки наличието/липса на обществена подкрепа, правило, което дезориентира мнозина)</li>
                            <li>социални медийни платформи, използвани в кампании под радара на наблюдението (ЦИК, Съвет за електронни медии и ДАНС) – условия, които трябва да бъдат преразгледани от законодателя</li>
                        </ul>


                        <h6>5. Мобилизиране на доброволци за предизборно наблюдение и обучение</h6>
                        <p>Прави впечатление, че въпреки че анализаторите от Съюз за честни избори / ОЧИ - ОЧИ/ наблюдаваха цялостния изборен процес, този доклад не обхваща предизборния и следизборния период. Вместо това, той се фокусира изключително върху нередностите и незаконните практики, които се проявиха по време на изборния ден (E-day).</p>
                        <p>Правилната подготовка на място обаче би била невъзможна без работата на място преди E-day. Броят на доброволците преди вота беше по-голям от тези, които наблюдаваха електронния ден. Някои са се отказали поради дългите работни часове/дни, на други не е издадена акредитация поради технически проблеми и прекалено сложен процес на акредитация, изпълняван от ЦИК.</p>
                        <p>Като общо правило , когато нередностите се случиха в електронния ден, имаше публично обсъждана информация за подготовката за тези скокове. Политическите партии носят основната отговорност за всяко действие на своите поддръжници и местни офиси и никаква злоупотреба с мащаб не би била възможна без предварително познаване на регионалните партийни централи.</p>

                        <h6>6. Разполагане на екипи от наблюдатели за пълно покриване на електронния ден в предварително идентифицираните ПС</h6>
                        <p>Общо 156 наблюдатели бяха обучени и изпратени от 6 часа сутринта на 2 април 2023 г., за да наблюдават в 96 от най-проблемните избирателни секции в България. В идеалния случай екип от двама беше настроен да работи през целия електронен ден в един PS. На няколко места обаче, поради практически причини, това не беше възможно. В такива ПС един наблюдател беше помолен да покрие цялото времетраене. Наблюдаваните избирателни секции са разположени в следните многомандатни избирателни райони: Благоевград, Бургас, Добрич, Габрово, Хасково, Кърджали , Пазарджик , Перник , Пловдив, Шумен, Силистра , Сливен, Смолян , София, София област, Варна, Велико Търново и Враца.</p>
                        <p>Като се има предвид натискът върху хората, свързани с местностите, извън големите градове, целта беше, когато е възможно, да се набират и обучават силно мотивирани лица от външна среда, несвързана с местния контекст. Този подход осигури безпристрастна оценка и екипът получи надеждни данни за анализ.</p>
                        <p>На наблюдателите бяха осигурени задължителни обучения, които обхващаха методическите указания на ЦИК за тези конкретни избори, както и интензивен курс за най-честите изборни злоупотреби, наблюдавани преди това от членове на СЗИЗ .</p>
                        <p>Наблюдателите попълниха формуляри за наблюдение на избори със стандартни въпросници за инсталирането и отварянето, по време на гласуването и процедурите по затваряне и преброяване.</p>

                        <h6>7. Е-Ден наблюдение</h6>
                        <p>Ключът в работата на EYES за изборите на 2 април 2023 г. беше наблюдението на електронния ден и последващият количествен и частично качествен анализ.</p>
                        <p>Количествените данни бяха попълнени от наблюдателите на място в платформа, специално създадена за избраните избирателни секции, предварително попълнена с информация за броя на избирателите и техния избор през последните 6 години, след изборите през 2017 г. Стандартизираните въпросници дадоха възможност за оценка на всички положителни и отрицателни аспекти в обстановката на избирателните секции и възможното влияние върху избора на избирателите. Отговорите на тези въпроси бяха регистрирани с прости отметки на телефоните на наблюдателите .</p>
                        <p>В допълнение към директното наблюдение в избирателните секции, партньорите на EYES в сътрудничество са организирали доброволци, които да използват систематичен подход, разработен от EYES, за дистанционно оценяване на нововъведеното видеонаблюдение на преброяването на бюлетините. Информацията , предоставена от наблюдението на потока на живо и записаните видеоклипове, също е включена в констатациите и препоръките.</p>
                        <p>За да се следи всяка динамика на местата през целия ден, от по-субективна гледна точка, Качественото отчитане беше планирано 4 пъти през електронния ден, чрез специално създадени чат групи в мобилно приложение и други споделени комуникационни канали. Тази перспектива осигуряваше във времето оценка на атмосферата, а не стриктността на процедурите. Радостно представяне срещу напрегната среда, съвместни усилия срещу опити да се грабне всяка възможност за печалба, бяха проследени в 96 проверени като с висока надеждност на секциите за наблюдение.</p>
                        <p>Те комбинират техники чрез квалифицирано наблюдение и докладване на екипите от наблюдатели , записани данни за всички аспекти, които EYES фокусира, включително:</p>
                        <ul>
                            <li>Дали избирателната секция е била подредена в съответствие със закона, насоките и предоставените указания</li>
                            <li>Дали на наблюдатели е било разрешено да наблюдават и дали им е било определено подходящо място за изпълнение на задълженията им, с ясна видимост на изборните процедури</li>
                            <li>Дали целият персонал на PS е присъствал, дали е бил адекватно обучен и дали е спазен партийният плурализъм (по този начин взаимен контрол)</li>
                            <li>Дали е имало нарушения на изборния процес, като многократно гласуване от едно и също лице, гласуване от чуждо име или с множество/принадлежащи на други лични карти</li>
                            <li>Дали е имало опити за компрометиране на анонимността на вота</li>
                            <li>Дали е имало съмнителни струпвания на хора пред избирателната секция, съмнения за контролиран вот</li>
                            <li>Дали е имало съобщения за присъствие на местни властници, които евентуално са оказвали натиск върху избирателите или са се опитвали да повлияят на вота им</li>
                            <li>Дали е имало прекъсвания в процеса на гласуване, проблеми с машините за гласуване и как ПС комисията се е справила с тях</li>
                            <li>Независимо дали ПС комисията е насочвала по някакъв начин избирателите относно техния избор на технология за гласуване: хартиени бюлетини или машини за гласуване</li>
                            <li>Дали е имало някакви нередности по време на процеса на преброяване на бюлетините или изготвяне на таблици</li>
                            <li>Дали е имало някакво влияние върху представянето на персонала на PS поради жизнения поток на процеса на преброяване, както и някакви лични/емоционални аспекти на този нов за изборите инструмент</li>
                            <li>И всички други нарушения, които са били пряко наблюдавани или докладвани</li>
                        </ul>
                        <p>В резултат на това от ОЧИ са подадени официално 32 сигнала за различни нарушения до отговорните институции, а именно ЦИК и РИК и МВР.</p>
                        <p>В допълнение, мрежата от журналисти отрази тези случаи на нарушения и злоупотреби по безпрецедентен начин, стриктно спазвайки нормата, че всяко нарушение заслужава внимание и бързи действия, а общата оценка за справедливостта на процеса идва само след надлежно отчитане на глобалното въздействие на тези предизвикателства към възможни промени в резултатите и/или разпределението на мандатите.</p>

                        <h6>8. Анализ и публично представяне на констатациите</h6>
                        <p>Въпреки че събира както количествени, така и качествени данни, този доклад се фокусира главно върху количествените констатации. Всички коментари и анализи обаче са взети под внимание и са частично базирани на качествените отговори на нашите наблюдатели на място.</p>
                        <p>Качественият анализ е изразен чрез препоръки за правни и административни адаптации и промени за предстоящите избори.</p>
                        <p className='fw-bold'>Статистически данни:</p>
                        <p className='fst-italic'>За удобство на читателя представените по-долу статистически данни са закръглени до най-близкото цяло число.</p>

                        <li>В близост до 10% от секциите има агитационни материали:</li>
                        <li>5% от избирателните секции не са подредени правилно, за да се гарантира тайната на вота:</li>
                        <li>5% от избирателните секции са имали оперативни проблеми, основно с машините за гласуване. ( Обърнете внимание, че според нашите експерти вероятната причина за това е „грешната“ хартия, резултат от неотдавнашния :</li>
                        <li>Нередности, свързани с видеонаблюдението на преброяването на бюлетините:
                            <ol>
                                <li>В 1/4 от видеоклиповете звукът не се чува/ясно</li>
                                <li>В 1/2 от видеоклиповете видеото не е започнало навреме</li>
                                <li>В 1/2 от видеоклиповете изгледът върху бюлетините и изборите не беше ясен</li>
                                <li>В над 1/2 от видеоклиповете изглежда, че членовете на избирателните секции се борят с процеса на преброяване и изготвянето на формуляр за протокол/резултати</li>
                            </ol>
                        </li>
                        <p>Като цяло изборният ден в наблюдаваните секции премина спокойно, при ниска избирателна активност, на места значително по-ниска от средната за страната. На някои места беше казано, че наличието на постоянни наблюдатели през целия ден е възпирало някои от незаконни практики, като многократно гласуване с лични карти на други и гласуване с въртележка. Липсват доказателства в подкрепа на тези твърдения и евентуалното въздействие не може да бъде количествено определено. Коментарите на избирателите обаче бяха изключително положителни за разположените наблюдатели. Някои заявиха, че има само наличието на независими наблюдатели гарантиран по-добър процес, повече отчетност и прозрачност на процедурите в тези високорискови ПУ.</p>
                        <p>Изглежда също така, че голямото мнозинство от нарушенията, идентифицирани от наблюдателите на EYES, се дължат на <span className='fw-bold'>липсата на познания на членовете на комисията на PS относно правилата</span>, най-вероятно в резултат на лошо или никакво обучение за тяхната роля. Тъй като всички членове на ПС по закон се номинират от политическите партии в предишния парламент, техните задължения и отговорността за гладкото протичане на процедурата не могат да бъдат преувеличени.</p>
                        <p>По сигнал от наблюдател на ОЧИ националната полиция задържа мъж, заподозрян за търговия с гласове в село Буковлък , Плевенско. Инициативата уведоми и МВР и ЦИК за предполагаема схема за купуване на гласове в гр. Перник.</p>
                        <p>В едно от избирателните секции в град Ихтиман , известен с компактния си вот, който се колебае между ГЕРБ и ДПС, местният ромски лидер Борис Аргиров , известен като Бони , се намеси пряко в работата на комисията по време на преброяването на бюлетините. След като ОЧИ уведоми ЦИК и МВР, случаят беше разкрит, но самото му наличие трябва да е повод за повече размисли.</p>
                        <p>Констатирана е и е докладвана незаконна агитация в едно избирателно помещение, водена от кмета на село Вакарел , Софийска област. В село Сърница , Родопска област, наблюдатели на Инициативата отчетоха множество проблеми в изборния процес, включително гласуване от чуждо име и др. Във Велинград е подаден сигнал за раздаване на безплатна храна срещу гласуване за определен кандидат. В няколко избирателни секции беше съобщено за присъствие на местен властник, който диктуваше как избирателите да гласуват. В ОЧИТЕ е получен сигнал и за превозване на хора с автобуси до избирателната секция в област Кърджали .</p>
                        <p>Поради сигнали на наблюдатели и последвалите действия на ЦИК, в няколко избирателни пункта беше спряно допускането до гласуване с изтекли, счупени/силно повредени лични карти. След уведомление за възможно заснемане/заснемане на гласуването, комисията в един от идентифицираните като високорискови избирателни пунктове единодушно прие да се свали сенника на екрана, за да се предотврати заснемането на вота.</p>
                        <p>Други проблемни практики, установени в изборния процес, са широко разпространеното наличие на тъмни кабини за гласуване вместо нововъведените паравани, транспортиране на избиратели и гласуване вместо избирател от член на ПС. </p>

                        <p>Въпреки че ниската избирателна активност в някои от избирателните секции с висок риск предполага, че политическите актьори не са мобилизирали обичайната маса в подкрепата си в тези специфични области, преференциалните резултати от гласуването на определени кандидати в други региони, известни с използването на клиентелистки практики , предполагат, че или партиите с установен клиентелизъм на място са успели да мобилизират успешно базата си, или че МВР е имало асиметричен успех в предотвратяването на контролирания вот и не е успяло да го спре напълно. Последните предположения са само предварителни хипотези, които ще бъдат напълно обосновани, след като Централната избирателна комисия оповести публично отворените данни за изборните резултати и екипът ги анализира внимателно.</p>
                        <p>Сивите зони в медийното законодателство позволиха неконтролирани политически кампании в социалните медии. Фалшивите новини, дезинформацията и малтретирането на опонентите, както и силно платеният изкуствено генериран интернет трафик са повлияли под радарите на регулаторите. Дори дейности, забранени от закона, все още се извършват поради липса на правна уредба относно дейностите в социалните медии.</p>
                        <p>Друга практика, която доказано оказва влияние върху избирателите, е некоректното представяне на проучванията от социологически агенции, които по закон трябва да споделят информация за използваната извадка и кой е поръчал и платил изследването. Това обаче не може да се прилага, когато информацията се споделя в нерегламентирани медии и/или партийно.</p>

                        <p className='fw-bold fst-italic'>Предимства на избраната методология за наблюдение и ограниченията</p>
                        <p>Предимствата са ясни, в края на електронния ден някои наблюдения бяха проверени, за да се изключат случайни пристрастия и грешки и резултатът е ясен и уместен за проблемните области, където продължава контролираният вот, подкопавайки доверието в процеса дори в области без смущения.</p>
                        <p>Ограниченията също са ясни, всички резултати и препоръки се основават конкретно на наблюдаваните проблеми и анализът изключва всички положителни аспекти и като цяло добре управлявания процес и надеждни резултати.</p>

                        <h4>Препоръки:</h4>
                        <ol>
                            <li>Правна рамка
                                <ol>
                                    <li>Преди да бъдат предложени, гласувани и приложени промени в правната и административната рамка, трябва да се проведат широки обществени консултации с анализ на експерти и гражданското общество, с участието на всички заинтересовани страни .</li>
                                    <li>Избирателните специалисти да бъдат включени като задължителна част от обществените консултации и международни органи за оценка и подкрепа, които да бъдат поканени да оценят и коментират предложените промени - давайки разбираема представа на обществеността за възможните практически резултати от такива промени. </li>
                                    <li>Всякакви съществени промени в изборния закон да важат не за следващите избори, а за тези след тях.</li>
                                </ol>
                            </li>
                            <li>Органи за управление на изборите
                                <ol>
                                    <li>Ясно дефиниране и разграничение между органите за вземане на решения (ЦИК, РИК) и избирателната администрация, която изпълнява тези решения. В момента същите ЦИК, РИК и т.н. вземат решенията и ги изпълняват, а на по-късен етап оценяват тези действия.</li>
                                    <li>Преструктуриране на избирателната администрация с въвеждане на задължителни тестове и включване на професионални елементи в подбора на кадри, а не само на партийна квота, както сега.</li>
                                    <li>Създаване на безпристрастна професионална избирателна администрация на всички нива, с оценка на нуждите, с перспектива за включване на критичните елементи на продължаващото обучение на избирателите , пълноценен отдел за обучение , отдел за анализ и развитие, отговарящ и за международните отношения.</li>
                                    <li>Ограничаване на възможността за промени в последната минута (без предоставяне на аргументи за тези промени) на избирателния персонал и замяната им само с обучени хора от резервния списък, които са преминали успешно тест за компетентност.</li>
                                    <p>Съюзът за честни избори наблюдава и заключава, че един от най-проблемните проблеми е допускането на грешки при попълването на протоколите. Това може да се отдаде на твърде сложните формуляри на протоколи, голям брой членове на избирателните комисии, отпаднали дни преди изборите, и недостатъчно налични материали за обучение и обучение за останалите членове. Във връзка с това от Съюза настоятелно препоръчват въвеждането на задължителни изпити за всички кандидати, които искат да станат членове на секционната избирателна комисия.</p>
                                </ol>
                            </li>
                            <li>Процедури в деня на изборите
                                <ol>
                                    <li>Законови разпоредби, позволяващи на SCOs да имат екип от двама наблюдатели в PS по време на E-day. Ограничаването на един SCO само до един наблюдател на PS е против международните стандарти и основните принципи на безпристрастно и безпристрастно наблюдение на избори. Понякога това може също така да увеличи заплахата за сигурността на наблюдателя на място, оставяйки на практика проблемните ПП ненаблюдавани поради липса на хора, желаещи сами да се намесват в местните структури на властта, особено в отдалечени райони или общности със силна местна зависимост.</li>
                                    <li>Преглед на формата на окончателните PS протоколи с цел опростяване на тези в съответствие с най-добрите съществуващи международни практики.</li>
                                    <li>Изчисляването на резултатите при машинно + хартиено гласуване да се извършва в районни избирателни комисии (РИК) или специално създадени центрове за изчисление.</li>
                                    <li>Съкращаване на изборния ден въз основа на анализ на избирателната активност и след обществени консултации. (13+1 часа е просто не необходими нито адекватен)</li>
                                </ol>
                            </li>
                            <li>Кампания Финанси
                                <ol>
                                    <li>Публичното финансиране на политическите партии да бъде обвързано с конкретни отговорности за разходите. 4.2. Процент от тази сума да се изразходва за обучение и изграждане на капацитет на партийните представители, изпратени в избирателните секции, и за гражданско образование на избирателите.</li>
                                </ol>
                            </li>
                            <li>Медия
                                <ol>
                                    <li>На представителите на медиите трябва да бъде осигурен достъп до помещенията с процедури като наблюдателите,</li>
                                    <li>Социалните медии, не само традиционните, трябва да бъдат наблюдавани от SEM по време на кампанията с фокус върху дните преди електронния ден и времето, когато се излъчват резултатите от екзитпола. Това включва страниците в социалните медии на традиционните медии.</li>
                                </ol>
                            </li>
                        </ol>

                        <h4>Приложения:</h4>
                        <ol>
                            <li>Отваряне на формуляр за гласуване (A)</li>
                            <li>Формуляр за гласуване (B)</li>
                            <li>Затваряне и броене от (C)</li>
                            <li>Доклад за инцидент (I)</li>
                            <li>Карта на наблюдаваните региони</li>
                        </ol>

                    </div>

                </div>
            </div>
        </section >
    );
};